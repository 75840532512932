import React, { Fragment } from 'react';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';

import { getProductUrl } from 'helpers/url';

import {
  SharedStyles,
  Container,
  Slider,
  SliderFavorite2,
  SliderElms,
} from '@matchbox-mobile/kenra-storybook';

const {
  StSectionTitle,
  StSectionMobileViewMore,
  StLink,
  StHoverOverlay,
} = SharedStyles;

const {
  StSliderFavorite2,
  StSliderFavorite2Desc,
  StSliderFavorite2Img,
} = SliderFavorite2;

const { StSliderLinkOverlay } = SliderElms;

export default function ProductSliderBig(props) {
  let {
    productHandles,
    collectionHandle,
    title,
    bigTitle,
    viewAllLink,
  } = props;

  const {
    allShopifyProduct,
    allShopifyCollection,
    allContentfulProduct,
  } = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        nodes {
          ...ShopifyProductFields
        }
      }
      allContentfulProduct {
        nodes {
          ...ContentfulProductFields
        }
      }
      allShopifyCollection {
        nodes {
          ...shopifyCollectionFields
        }
      }
    }
  `);

  if (collectionHandle) {
    let collection = allShopifyCollection.nodes.find(
      c => c.handle === collectionHandle
    );
    if (!collection) {
      console.error('ProductSlider: collection not found');
      return null;
    }

    productHandles = collection.products.map(p => p.handle);
  }
  if (!Array.isArray(productHandles) || productHandles.length === 0)
    return null;

  let products = productHandles.map(handle => {
    let shopifyProduct = allShopifyProduct.nodes.find(p => p.handle === handle);
    let contentfulProduct = allContentfulProduct.nodes.find(
      p => p.shopifyProduct.handle === handle
    );

    return {
      handle,
      shopifyProduct,
      contentfulProduct,
    };
  });

  let Wrapper = bigTitle ? StSectionMobileViewMore : Fragment;

  return (
    <Wrapper>
      {title && (
        <Container>
          {bigTitle ? (
            <StSectionTitle>
              <h2>Latest Tutorials</h2>
              {viewAllLink && (
                <StLink>
                  <a href={viewAllLink}>{`VIEW ALL (${products.length})`}</a>
                </StLink>
              )}
            </StSectionTitle>
          ) : (
            <StSectionTitle>
              <h2>{title}</h2>
              {viewAllLink && (
                <StLink>
                  <a href={viewAllLink}>{`VIEW ALL (${products.length})`}</a>
                </StLink>
              )}
            </StSectionTitle>
          )}
        </Container>
      )}
      <Slider settings={{ slidesPerView: 'auto' }}>
        {products.map((product, index) => {
          let { contentfulProduct, shopifyProduct, handle } = product;
          let bigImage = get(
            contentfulProduct,
            'stylistFavoritesBigImage.localFile.publicURL'
          );

          return (
            <StSliderFavorite2 key={`${product.id}_${index}`}>
              {bigImage ? (
                <img
                  style={{ objectFit: 'cover', height: 690 }}
                  src={bigImage}
                  alt=""
                />
              ) : (
                <div style={{ height: 690, backgroundColor: '#4a4a4a' }} />
              )}

              <StHoverOverlay>
                <StSliderFavorite2Desc>
                  <StSliderFavorite2Img>
                    <img
                      style={{ objectFit: 'contain', maxHeight: 490 }}
                      src={get(shopifyProduct, 'images.0.localFile.publicURL')}
                      alt=""
                    />
                  </StSliderFavorite2Img>
                  <h2>{get(shopifyProduct, 'title')}</h2>
                  <h3>{get(contentfulProduct, 'description')}</h3>
                  <StLink>
                    <a href={getProductUrl(handle)}>Shop Now</a>
                  </StLink>
                </StSliderFavorite2Desc>
                <StSliderLinkOverlay>
                  <a href={getProductUrl(handle)}>
                    {get(shopifyProduct, 'title')}
                  </a>
                </StSliderLinkOverlay>
              </StHoverOverlay>
            </StSliderFavorite2>
          );
        })}
      </Slider>
    </Wrapper>
  );
}
