import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import ProductSliderBig from 'blocks/ProductSliderBig';

import {
  Spacing,
  SharedStyles,
  Container,
  ProductStyle,
  ImageTextInfo,
  VideoBlock,
  StyleBlock,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StLinkMore, StTitle } = SharedStyles;

export default function PlatinumLanding({ page }) {
  let video1id = getYouTubeId(page.video1Url);
  let video2id = getYouTubeId(page.video2Url);

  return (
    <>
      <Spacing removeSpaceTop>
        <VideoBlock
          renderVideo
          imgUrl={get(page, 'videoPreview.localFile.publicURL')}
        >
          <video preload="metadata">
            <source
              src={get(page, 'video.localFile.publicURL')}
              type="video/mp4"
            />
            Video not supported.
          </video>
        </VideoBlock>
        <StyleBlock
          title={page.title}
          subTitle={page.subtitle}
          linkText="SHOP COLLECTION"
          linkHref="/collection/kenra-platinum"
          linkMobileText="Watch More"
          linkMobileMore="/video"
          items={[
            {
              title: page.video1Title,
              text: page.video1Text,
              btnSizeMedium: true,
              bgImg: getPreviewUrl(video1id),
              videoType: 'youtube',
              autoPlay: true,
              allow:
                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
              videoOptions: [
                {
                  src: getEmbedUrl(video1id),
                },
              ],
            },
            {
              title: page.video2Title,
              text: page.video2Text,
              btnSizeMedium: true,
              bgImg: getPreviewUrl(video2id),
              videoType: 'youtube',
              autoPlay: true,
              allow:
                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
              videoOptions: [
                {
                  src: getEmbedUrl(video2id),
                },
              ],
            },
          ]}
        />
      </Spacing>

      <Spacing removeSpaceBottomMobile>
        <StSectionInfo useMobileBg>
          <Container>
            <StTitle>
              <h2>{page.contentTitle}</h2>
            </StTitle>

            {documentToReactComponents(get(page, 'content.json'))}

            <StLinkMore styleBig>
              <a href="/collection/kenra-platinum">VIEW ALL PRODUCTS</a>
            </StLinkMore>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing removeSpaceTopMobile>
        <ProductStyle
          items={[
            {
              bg: get(page, 'leftImage.localFile.publicURL'),
              title: page.leftImageTitle,
              text: page.leftImageText,
              linkMoreText: 'VIEW ALL',
              linkMoreHref: page.leftImageUrl,
            },
            {
              bg: get(page, 'rightImage.localFile.publicURL'),
              title: page.rightImageTitle,
              text: page.rightImageText,
              linkMoreText: 'VIEW ALL',
              linkMoreHref: page.rightImageUrl,
            },
          ]}
        />
      </Spacing>

      <Spacing>
        <ImageTextInfo
          items={[
            {
              img: get(page, 'bottomImage1.localFile.publicURL'),
              title: page.bottomTitle1,
              text: page.bottomText1,
            },
            {
              img: get(page, 'bottomImage2.localFile.publicURL'),
              title: page.bottomTitle2,
              text: page.bottomText2,
            },
            {
              img: get(page, 'bottomImage3.localFile.publicURL'),
              title: page.bottomTitle3,
              text: page.bottomText3,
            },
          ]}
        />
      </Spacing>

      <Spacing>
        <ProductSliderBig
          title="Stylist favorites"
          collectionHandle={get(page, 'stylistFavorites.handle')}
          viewAllLink={get(page, 'stylistFavoritesViewAllLink')}
        />
      </Spacing>
    </>
  );
}
