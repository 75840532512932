import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import PlatinumLanding from 'components/PlatinumLanding';

export default function KenraPlatinumLandingPage(props) {
  let {
    data: { contentfulPlatinumLanding },
  } = props;

  return (
    <Layout>
      <PlatinumLanding page={contentfulPlatinumLanding} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulPlatinumLanding {
      id
      video2Url
      video2Title
      video2Text
      video1Url
      video1Title
      video1Text
      subtitle
      title
      rightImageUrl
      rightImageTitle
      rightImageText
      rightImage {
        localFile {
          publicURL
        }
      }
      leftImageUrl
      leftImageTitle
      leftImageText
      leftImage {
        localFile {
          publicURL
        }
      }
      bottomTitle3
      bottomTitle2
      bottomTitle1
      bottomText3
      bottomText2
      bottomText1
      bottomImage3 {
        localFile {
          publicURL
        }
      }
      bottomImage2 {
        localFile {
          publicURL
        }
      }
      bottomImage1 {
        localFile {
          publicURL
        }
      }
      content {
        json
      }
      contentTitle
      video {
        localFile {
          publicURL
        }
      }
      videoPreview {
        localFile {
          publicURL
        }
      }
      stylistFavorites {
        handle
      }
      stylistFavoritesViewAllLink
    }
  }
`;
